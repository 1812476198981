.scrollable.is-enabled
{
    overflow: hidden !important;
}

.is-enabled .scrollable-container
{
    overflow: hidden !important;

    -webkit-box-sizing: content-box !important;
       -moz-box-sizing: content-box !important;
            box-sizing: content-box !important;
}

.is-enabled .scrollable-container::-webkit-scrollbar
{
    width: 0;
    height: 0;

    -webkit-appearance: none;
}

.scrollable-vertical.is-enabled .scrollable-container
{
    overflow-y: scroll !important;
}

.scrollable-horizontal.is-enabled .scrollable-container
{
    overflow-x: scroll !important;
}

.is-enabled .scrollable-content
{
    position: relative !important;

    overflow: visible !important;

    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}
.is-enabled .scrollable-content:before,
.is-enabled .scrollable-content:after
{
    display: table; 

    content: ' ';
}
.is-enabled .scrollable-content:after
{
    clear: both;
}

.scrollable-bar
{
    line-height: 0;

    position: absolute;
    right: 0;
    bottom: 0;

    overflow: hidden;

    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;

    -webkit-user-select: none;
    -webkit-user-select: none;
       -moz-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
        -ms-user-select: none;
            user-select: none;
            user-select: none; 
    -webkit-transition: opacity .5s;
         -o-transition: opacity .5s;
            transition: opacity .5s;

    border-radius: 2px;

    user-focus: ignore;
    user-input: disabled;
    -webkit-touch-callout: none;
}
.is-disabled .scrollable-bar
{
    display: none;
}
.scrollable-bar-hide
{
    -webkit-transition-delay: 400ms;
         -o-transition-delay: 400ms;
            transition-delay: 400ms;

    opacity: 0;
}
.scrollable-bar.is-dragging
{
    opacity: 1;
}
.scrollable-bar.is-disabled
{
    display: none;
}
.scrollable-bar-handle
{
    line-height: 0;

    position: absolute;
    top: 0;
    left: 0;

    cursor: pointer;
    -webkit-transition: width, height .5s;
         -o-transition: width, height .5s;
            transition: width, height .5s;

    border-radius: 2; 
    background: rgba(158, 158, 158, .6);
}
.scrollable-bar-vertical
{
    width: 4px;
    height: 100%;
    height: -webkit-calc(100% - 8px);
    height:         calc(100% - 8px); 
    margin: 4px 2px;
}
.scrollable-bar-vertical .scrollable-bar-handle
{
    width: 100%;
}
.scrollable-bar-horizontal
{
    width: 100%;
    width: -webkit-calc(100% - 8px);
    width:         calc(100% - 8px); 
    height: 4px;
    margin: 2px 4px;
}
.scrollable-bar-vertical.is-dragging,
.scrollable-bar-vertical.is-hovering
{
    width: 8px;
    margin: 4px 1px;
}
.scrollable-bar-horizontal.is-dragging,
.scrollable-bar-horizontal.is-hovering
{
    height: 8px;
    margin: 1px 4px;
}
.scrollable-bar-horizontal .scrollable-bar-handle
{
    height: 100%;
}

.scrollable.is-dragging .scrollable-handle,
.scrollable.is-hovering .scrollable-handle
{
    border-radius: 8px;
}

.scrollable.is-dragging .scrollable-bar-handle,
.scrollable.is-hovering .scrollable-bar-handle
{
    border-radius: 8px;
}

.scrollable.is-scrolling .scrollable-bar
{
    -webkit-transition: opacity 0;
         -o-transition: opacity 0;
            transition: opacity 0;

    opacity: 1;
}

.scrollable.is-dragging
{
    -webkit-user-select: none;
    -webkit-user-select: none;
       -moz-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
        -ms-user-select: none;
            user-select: none;
            user-select: none; 

    user-focus: ignore;
    user-input: disabled;
    -webkit-touch-callout: none;
}
.scrollable.is-dragging .scrollable-bar-handle
{
    background: rgba(158, 158, 158, .8) !important;
}

.scrollable-inverse.scrollable .scrollable-bar-handle
{
    background: rgba(238, 238, 238, .5);
}

.scrollable-inverse.scrollable.is-dragging .scrollable-bar-handle
{
    background: rgba(238, 238, 238, .7) !important;
}

.scrollable-shadow.scrollable.is-dragging .scrollable-bar,
.scrollable-shadow.scrollable.is-hovering .scrollable-bar
{
    background: rgba(224, 224, 224, .5);
}

.scrollable-inverse.scrollable-shadow.scrollable.is-dragging .scrollable-bar,
.scrollable-inverse.scrollable-shadow.scrollable.is-hovering .scrollable-bar
{
    background: rgba(238, 238, 238, .3);
}
