@charset 'UTF-8';
@font-face
{
    font-family: 'Brand Icons';
    font-weight: normal;
    font-style: normal; 

    src: url('./brand-icons.eot?v=0.3.2');
    src: url('./brand-icons.eot?#iefix&v=0.3.2') format('embedded-opentype'), url('./brand-icons.woff2?v=0.3.2') format('woff2'), url('./brand-icons.woff?v=0.3.2') format('woff'), url('./brand-icons.ttf?v=0.3.2') format('truetype'), url('./brand-icons.svg?v=0.3.2#brand-icons') format('svg');
}

[class^='bd-'],
[class*='bd-']
{
    font-family: 'Brand Icons';
    font-weight: normal;
    font-style: normal;

    position: relative;

    display: inline-block;

    -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
         -o-transform: translate(0, 0);
            transform: translate(0, 0); 

    text-rendering: auto;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.bd-behance:before
{
    content: '';
}

.bd-blogger:before
{
    content: '';
}

.bd-delicious:before
{
    content: '';
}

.bd-deviantart:before
{
    content: '';
}

.bd-dribbble:before
{
    content: '';
}

.bd-facebook:before
{
    content: '';
}

.bd-flickr:before
{
    content: '';
}

.bd-foursquare:before
{
    content: '';
}

.bd-github:before
{
    content: '';
}

.bd-google-plus:before
{
    content: '';
}

.bd-instagram:before
{
    content: '';
}

.bd-lastfm:before
{
    content: '';
}

.bd-linkedin:before
{
    content: '';
}

.bd-pinterest:before
{
    content: '';
}

.bd-quora:before
{
    content: '';
}

.bd-stackoverflow:before
{
    content: '';
}

.bd-rdio:before
{
    content: '';
}

.bd-reddit:before
{
    content: '';
}

.bd-soundcloud:before
{
    content: '';
}

.bd-spotify:before
{
    content: '';
}

.bd-stumbleupon:before
{
    content: '';
}

.bd-tumblr:before
{
    content: '';
}

.bd-twitter:before
{
    content: '';
}

.bd-viadeo:before
{
    content: '';
}

.bd-vimeo:before
{
    content: '';
}

.bd-vine:before
{
    content: '';
}

.bd-yelp:before
{
    content: '';
}

.bd-openid:before
{
    content: '';
}

.bd-vk:before
{
    content: '';
}

.bd-yahoo:before
{
    content: '';
}

.bd-xing:before
{
    content: '';
}

.bd-youtube:before
{
    content: '';
}

.bd-android:before
{
    content: '';
}

.bd-apple:before
{
    content: '';
}

.bd-windows:before
{
    content: '';
}

.bd-linux:before
{
    content: '';
}

.bd-wordpress:before
{
    content: '';
}

.bd-drupal:before
{
    content: '';
}

.bd-joomla:before
{
    content: '';
}

.bd-squarespace:before
{
    content: '';
}

.bd-medium:before
{
    content: '';
}

.bd-dropbox:before
{
    content: '';
}

.bd-codepen:before
{
    content: '';
}

.bd-jsfiddle:before
{
    content: '';
}

.bd-evernote:before
{
    content: '';
}

.bd-envato:before
{
    content: '';
}

.bd-skype:before
{
    content: '';
}

.bd-paypal:before
{
    content: '';
}

.bd-feed:before
{
    content: '';
}

.bd-html5:before
{
    content: '';
}

.bd-css3:before
{
    content: '';
}

.bd-angular:before
{
    content: '';
}

.bd-webchat:before
{
    content: '';
}

.bd-qq:before
{
    content: '';
}

.bd-zhihu:before
{
    content: '';
}

.bd-weibo:before
{
    content: '';
}

.bd-douban:before
{
    content: '';
}

.bd-baidu:before
{
    content: '';
}
