@charset 'UTF-8';
@font-face
{
    font-family: 'Web Icons';
    font-weight: normal;
    font-style: normal; 

    src: url('./web-icons.eot?v=0.2.3');
    src: url('./web-icons.eot?#iefix&v=0.2.3') format('embedded-opentype'), url('./web-icons.woff2?v=0.2.3') format('woff2'), url('./web-icons.woff?v=0.2.3') format('woff'), url('./web-icons.ttf?v=0.2.3') format('truetype'), url('./web-icons.svg?v=0.2.3#web-icons') format('svg');
}

[class^='wb-'],
[class*=' wb-']
{
    font-family: 'Web Icons';
    font-weight: normal;
    font-style: normal;

    position: relative;

    display: inline-block;

    -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
         -o-transform: translate(0, 0);
            transform: translate(0, 0); 

    text-rendering: auto;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.wb-dashboard:before
{
    content: '';
}

.wb-inbox:before
{
    content: '';
}

.wb-cloud:before
{
    content: '';
}

.wb-bell:before
{
    content: '';
}

.wb-book:before
{
    content: '';
}

.wb-bookmark:before
{
    content: '';
}

.wb-tag:before
{
    content: '';
}

.wb-library:before
{
    content: '';
}

.wb-share:before
{
    content: '';
}

.wb-reply:before
{
    content: '';
}

.wb-refresh:before
{
    content: '';
}

.wb-move:before
{
    content: '';
}

.wb-chat:before
{
    content: '';
}

.wb-chat-working:before
{
    content: '';
}

.wb-chat-text:before
{
    content: '';
}

.wb-chat-group:before
{
    content: '';
}

.wb-envelope:before
{
    content: '';
}

.wb-envelope-open:before
{
    content: '';
}

.wb-user:before
{
    content: '';
}

.wb-user-circle:before
{
    content: '';
}

.wb-users:before
{
    content: '';
}

.wb-user-add:before
{
    content: '';
}

.wb-grid-9:before
{
    content: '';
}

.wb-grid-4:before
{
    content: '';
}

.wb-menu:before
{
    content: '';
}

.wb-layout:before
{
    content: '';
}

.wb-fullscreen:before
{
    content: '';
}

.wb-fullscreen-exit:before
{
    content: '';
}

.wb-expand:before
{
    content: '';
}

.wb-contract:before
{
    content: '';
}

.wb-arrow-expand:before
{
    content: '';
}

.wb-arrow-shrink:before
{
    content: '';
}

.wb-desktop:before
{
    content: '';
}

.wb-mobile:before
{
    content: '';
}

.wb-signal:before
{
    content: '';
}

.wb-power:before
{
    content: '';
}

.wb-more-horizontal:before
{
    content: '';
}

.wb-more-vertical:before
{
    content: '';
}

.wb-globe:before
{
    content: '';
}

.wb-map:before
{
    content: '';
}

.wb-flag:before
{
    content: '';
}

.wb-pie-chart:before
{
    content: '';
}

.wb-stats-bars:before
{
    content: '';
}

.wb-pluse:before
{
    content: '';
}

.wb-home:before
{
    content: '';
}

.wb-shopping-cart:before
{
    content: '';
}

.wb-payment:before
{
    content: '';
}

.wb-briefcase:before
{
    content: '';
}

.wb-search:before
{
    content: '';
}

.wb-zoom-in:before
{
    content: '';
}

.wb-zoom-out:before
{
    content: '';
}

.wb-download:before
{
    content: '';
}

.wb-upload:before
{
    content: '';
}

.wb-sort-asc:before
{
    content: '';
}

.wb-sort-des:before
{
    content: '';
}

.wb-graph-up:before
{
    content: '';
}

.wb-graph-down:before
{
    content: '';
}

.wb-replay:before
{
    content: '';
}

.wb-edit:before
{
    content: '';
}

.wb-pencil:before
{
    content: '';
}

.wb-rubber:before
{
    content: '';
}

.wb-crop:before
{
    content: '';
}

.wb-eye:before
{
    content: '';
}

.wb-eye-close:before
{
    content: '';
}

.wb-image:before
{
    content: '';
}

.wb-gallery:before
{
    content: '';
}

.wb-video:before
{
    content: '';
}

.wb-camera:before
{
    content: '';
}

.wb-folder:before
{
    content: '';
}

.wb-clipboard:before
{
    content: '';
}

.wb-order:before
{
    content: '';
}

.wb-file:before
{
    content: '';
}

.wb-copy:before
{
    content: '';
}

.wb-add-file:before
{
    content: '';
}

.wb-print:before
{
    content: '';
}

.wb-calendar:before
{
    content: '';
}

.wb-time:before
{
    content: '';
}

.wb-trash:before
{
    content: '';
}

.wb-plugin:before
{
    content: '';
}

.wb-extension:before
{
    content: '';
}

.wb-memory:before
{
    content: '';
}

.wb-settings:before
{
    content: '';
}

.wb-scissor:before
{
    content: '';
}

.wb-wrench:before
{
    content: '';
}

.wb-hammer:before
{
    content: '';
}

.wb-lock:before
{
    content: '';
}

.wb-unlock:before
{
    content: '';
}

.wb-volume-low:before
{
    content: '';
}

.wb-volume-high:before
{
    content: '';
}

.wb-volume-off:before
{
    content: '';
}

.wb-pause:before
{
    content: '';
}

.wb-play:before
{
    content: '';
}

.wb-stop:before
{
    content: '';
}

.wb-musical:before
{
    content: '';
}

.wb-random:before
{
    content: '';
}

.wb-reload:before
{
    content: '';
}

.wb-loop:before
{
    content: '';
}

.wb-text:before
{
    content: '';
}

.wb-bold:before
{
    content: '';
}

.wb-italic:before
{
    content: '';
}

.wb-underline:before
{
    content: '';
}

.wb-format-clear:before
{
    content: '';
}

.wb-text-type:before
{
    content: '';
}

.wb-table:before
{
    content: '';
}

.wb-attach-file:before
{
    content: '';
}

.wb-paperclip:before
{
    content: '';
}

.wb-link-intact:before
{
    content: '';
}

.wb-link:before
{
    content: '';
}

.wb-link-broken:before
{
    content: '';
}

.wb-indent-increase:before
{
    content: '';
}

.wb-indent-decrease:before
{
    content: '';
}

.wb-align-justify:before
{
    content: '';
}

.wb-align-left:before
{
    content: '';
}

.wb-align-center:before
{
    content: '';
}

.wb-align-right:before
{
    content: '';
}

.wb-list-numbered:before
{
    content: '';
}

.wb-list-bulleted:before
{
    content: '';
}

.wb-list:before
{
    content: '';
}

.wb-emoticon:before
{
    content: '';
}

.wb-quote-right:before
{
    content: '';
}

.wb-code:before
{
    content: '';
}

.wb-code-working:before
{
    content: '';
}

.wb-code-unfold:before
{
    content: '';
}

.wb-chevron-right:before
{
    content: '';
}

.wb-chevron-left:before
{
    content: '';
}

.wb-chevron-left-mini:before
{
    content: '';
}

.wb-chevron-right-mini:before
{
    content: '';
}

.wb-chevron-up:before
{
    content: '';
}

.wb-chevron-down:before
{
    content: '';
}

.wb-chevron-up-mini:before
{
    content: '';
}

.wb-chevron-down-mini:before
{
    content: '';
}

.wb-arrow-left:before
{
    content: '';
}

.wb-arrow-right:before
{
    content: '';
}

.wb-arrow-up:before
{
    content: '';
}

.wb-arrow-down:before
{
    content: '';
}

.wb-dropdown:before
{
    content: '';
}

.wb-dropup:before
{
    content: '';
}

.wb-dropright:before
{
    content: '';
}

.wb-dropleft:before
{
    content: '';
}

.wb-sort-vertical:before
{
    content: '';
}

.wb-triangle-left:before
{
    content: '';
}

.wb-triangle-right:before
{
    content: '';
}

.wb-triangle-down:before
{
    content: '';
}

.wb-triangle-up:before
{
    content: '';
}

.wb-check-circle:before
{
    content: '';
}

.wb-check:before
{
    content: '';
}

.wb-check-mini:before
{
    content: '';
}

.wb-close:before
{
    content: '';
}

.wb-close-mini:before
{
    content: '';
}

.wb-plus-circle:before
{
    content: '';
}

.wb-plus:before
{
    content: '';
}

.wb-minus-circle:before
{
    content: '';
}

.wb-minus:before
{
    content: '';
}

.wb-alert-circle:before
{
    content: '';
}

.wb-alert:before
{
    content: '';
}

.wb-help-circle:before
{
    content: '';
}

.wb-help:before
{
    content: '';
}

.wb-info-circle:before
{
    content: '';
}

.wb-info:before
{
    content: '';
}

.wb-warning:before
{
    content: '';
}

.wb-heart:before
{
    content: '';
}

.wb-heart-outline:before
{
    content: '';
}

.wb-star:before
{
    content: '';
}

.wb-star-half:before
{
    content: '';
}

.wb-star-outline:before
{
    content: '';
}

.wb-thumb-up:before
{
    content: '';
}

.wb-thumb-down:before
{
    content: '';
}

.wb-small-point:before
{
    content: '';
}

.wb-medium-point:before
{
    content: '';
}

.wb-large-point:before
{
    content: '';
}
