/**
 * Override css
 */


/**
 * Body
 */
body{
	font-weight: 300;
}


/**
 * Link
 */
.page-content{

	a{

		color: $secondary-color;
		text-decoration: underline;

		&.dropdown-toggle{

			color: $text-color-primary;
			text-decoration: none;

		}

	}

}

/**
 * Navbar
 */
.navbar-default{

	.navbar-header{

		background-color: $color-white;

	}

}


/**
 * Navbar icon background color
 */
.navbar-container{

	&.navbar-container-blue{

		background-color: $primary-color;

	}

}


/**
 * Icons in navbar
 */
.navbar-default{

	// Bigger than 767px
	.navbar-toolbar{

		.hamburger{

			.hamburger-bar{

				background-color: $color-white;

			}

			&:after{

				background-color: $color-white;

			}

			&:before {

		    	background-color: $color-white;

			}

		}

		.nav-link{

		    color: $color-white;

		    &:hover{

		    	color: $color-white;
		    	background-color: $secondary-color;

		    }

		    &:focus{

		    	color: $color-white;
		    	background-color: $secondary-color;
		    	
		    }

		    &.active{

		    	color: $color-white;
		    	background-color: $secondary-color;

		    	&:focus{

		    		color: $color-white;
		    		background-color: $secondary-color;

		    	}

		    	&:hover{

		    		color: $color-white;
		    		background-color: $secondary-color;

		    	}

		    }

		    &:hover{

		    	background-color: $secondary-color;

		    }

		}

		.icon-fullscreen{

			font-size: 26px;
			font-weight: 300;

		}

	}

	
	.hamburger{

			&:after{

				@media (max-width: 767px) {
					
					background-color: $color-black !important;

				}

			}

			&:before {

		    	@media (max-width: 767px) {
		    		
		    		background-color: $color-black !important;

		    	}

			}

		.hamburger-bar{

			@media (max-width: 767px) {
				
				background-color: $color-black !important;

			}

			&:after{

				@media (max-width: 767px) {
					
					background-color: $color-black !important;

				}

			}

			&:before {

		    	@media (max-width: 767px) {
		    		
		    		background-color: $color-black !important;

		    	}

			}

		}

	}

	.navbar-toggler{

		@media (max-width: 767px) {
			
			color: $color-black !important;

		}

	}

}


/**
 * Navbar logo
 */
.navbar-brand{

	padding: 0.9rem 1.429rem;

	@media (max-width: 1199px) {
		padding: 0rem;
		padding-top: 21px;
	}

	.navbar-brand-text{

		color: $primary-color;
		font-size: 14px;
		font-weight: 300;

	}

	@media (max-width: 1199px) {

		.navbar-brand-logo{

			height: 1.286rem;
			padding: 0rem;

		}

	}

	a:hover{

		text-decoration: none;

	}

}


/**
 * Submenu
 */
 .site-menu{

 	.site-menu-sub{

    	font-size: 12px;

    	.site-menu-header{
    		
    		font-weight: 500;
    		margin-left: 50px;

    	}

 	}

 }


/**
 * Page header title
 */
.page-header-title{

	&.font-size-50{

		@media (max-width: 440px) {

			font-size: 34px !important;

		}

	}

	&.blue-grey-800{

		color: $primary-color !important;

	}

}


/**
 * Logo menubar fold
 */
.site-menubar-fold{

	.navbar-brand{
	
		padding: 0rem;
		padding-top: 21px;

		.navbar-brand-logo{

			height: 1.286rem;
			padding: 0rem;

		}

	}

}


/**
 * Panel
 */
.panel{

	&.is-fullscreen {

     	overflow-y: auto;

    }

}


/**
 * Panel title
 */
.panel-title{

	font-size: 1.715rem;

}   

/**
 * Error page
 */
.error-page{

	header{ 

		h1 {
	    
	    	font-size: 10em;
	    	font-weight: 400;
	    	margin-top: 0px;
		}

		p{
		    font-size: 30px;
		    margin-bottom: 30px;
		}

	}

	.fa-big{

		font-size: 100px;
		color: $warning-color;

	}

}


/**
 * Footable
 */
 th{

 	&.width-10-pr{

 		width: $width-10-pr;

 	}

 }


/**
 * Footable
 */
th{
	
	&.footable-sortable{

		span{

			&.fooicon{

				opacity: 1;

			}

		}

	}

	&.hide-sorting{

		span{

			&.fooicon{

				opacity: 0;

			}

		}

	}

}

tbody{

	tr{

		&:nth-child(even){
   			
   			background-color: rgba(243, 247, 249, 0.5);
   	
   		}

   		&:hover{

   			background-color: #F3F7F9 !important;

   		}

   	}

}


/**
 * Dashboard page
 */
 .dashboard{

	.checklist-link-wrapper{

			margin-bottom: 15px;
    		display: inline-block;
		
		a{
			color: #ff9800;
			text-decoration: none;
		}
		
		i{
			text-decoration: none;
			margin-right: 5px;
		}

		span{
			text-decoration: underline;
		}

	}

}


/**
 * Customer page
 */
.customer{

	.logo-container{

		img{

			max-width: 100%;
			padding: 15px;
			border: 2px dashed #E1E1E1;

		}

		.btn_delete_logo{

			margin-top: 10px;
			display: block;

		}

	}	

}


/**
 * Checklist page
 */
.checklist{

	.image-container{

		img{

			max-width: 30%;
			padding: 15px;
			border: 2px dashed #E1E1E1;

		}

		.btn_delete_img{

			margin-top: 10px;
			display: block;

		}

	}

	.checklist-input{
		display: none;
	}
	
	.list-filter-wrapper{
		margin-bottom: 10px;
	}

	.register-date-wrapper{

		.no-checkbox{
			margin-left: 23px;
		}
	}

}


/**
 * Sweet alert
 */
.swal2-container{

	z-index: 1500 !important;

	.swal2-buttonswrapper{

		button{

			&:first-child{

				margin-right: 10px !important;

			}

		}

	}

}


/**
 * Cancel button
 */
.btn-cancel{

	background-color: #C1C1C1;
	color: #FFFFFF;

}

.btn-ok {

   	background-color: #62a8ea;
    border-color: #62a8ea;
    color: #FFFFFF;
    
}


/**
 * General classes
 */
.margin-top-60{

	margin-top: $margin-top-60;

}

.margin-right-10{

	margin-right: $margin-right-10;

}

.margin-right-20{

	margin-right: $margin-right-20;

}

.fa-15{

	font-size: $fa-15;

}

.fa-25{

	font-size: $fa-25;

}

.padding-20{

	padding: $padding-20;

}

.padding-left-0{

	padding-left: $padding-left-0;

}

.padding-left-10{

	padding-left: $padding-left-10;

}

.padding-right-10{

	padding-right: $padding-right-10;

}

.width-100-pr{

	width: $width-100-pr;

}

.bg-grey{

	background-color: $bg-grey;

}

.nowrap{
	white-space: nowrap;
}

.inline-block{
	display: inline-block;
}

.action-spinner{
	vertical-align: middle;
	margin-left: 10px;
	
	&.hide{
		display: none;
	}

	&.show{
		display: inline-block;
	}
}