table.footable > tfoot > tr.footable-paging > td > ul.pagination {
	margin: 10px 0 0 0;
}
table.footable > tfoot > tr.footable-paging > td > span.label {
	display: inline-block;
	margin: 0 0 10px 0;
	padding: 4px 10px;
}
table.footable > tfoot > tr.footable-paging > td,
table.footable-paging-center > tfoot > tr.footable-paging > td {
	text-align: center;
}
table.footable-paging-left > tfoot > tr.footable-paging > td {
	text-align: left;
}
table.footable-paging-right > tfoot > tr.footable-paging > td {
	text-align: right;
}
ul.pagination > li.footable-page {
	display: none;
}
ul.pagination > li.footable-page.visible {
	display: inline;
}




.footable>tfoot .pagination {
    margin: 0;
}

.pagination {
    display: inline-block;
    padding-left: 0;
    border-radius: 3px;
}

.pagination>li {
    display: inline;
}

.pagination>li:first-child>a, .pagination>li:first-child>span {
    margin-left: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.pagination>.disabled>a, .pagination>.disabled>a:focus, .pagination>.disabled>a:hover, .pagination>.disabled>span, .pagination>.disabled>span:focus, .pagination>.disabled>span:hover {
    color: #ccd5db;
    cursor: not-allowed;
    background-color: transparent;
    border-color: #e4eaec;
}

.pagination li>a, .pagination li>span {
    padding: 9px 15px;
    -webkit-transition: background .2s ease-out,border-color 0s ease-out,color .2s ease-out;
    -o-transition: background .2s ease-out,border-color 0s ease-out,color .2s ease-out;
    transition: background .2s ease-out,border-color 0s ease-out,color .2s ease-out;
}

@media (max-width: 399px) {
	
	.pagination li>a, .pagination li>span {
		padding: 7px 13px;
	}

}

.pagination>li>a, .pagination>li>span {
    position: relative;
    float: left;
    margin-left: -1px;
    line-height: 1.57142857;
    text-decoration: none;
    border: 1px solid #e4eaec;
}

.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
    z-index: 3;
    color: #fff;
    cursor: default;
    background-color: #62a8ea;
    border-color: #62a8ea;
}