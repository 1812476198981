/**
 * Buttons
 */

.btn-primary{
	background-color: $blue;
	border-color: $blue;

    &:hover{
        
        background-color: $bg-blue;
        border-color: $bg-blue;

    }

    &:focus{
        
        background-color: $bg-blue;
        border-color: $bg-blue;

    }

    &.focus{
        
        background-color: $bg-blue;
        border-color: $bg-blue;

    }

    &.disabled{

        background-color: $disabled-color;
        border-color: $disabled-color;

        &:hover{
            
            background-color: $disabled-color;
            border-color: $disabled-color;

        }

    }

    &[disabled]{

        background-color: $disabled-color;
        border-color: $disabled-color;

        &:hover{

            background-color: $disabled-color;
            border-color: $disabled-color;

        }

    }

}

.btn-group{

    box-shadow: none;

}


/**
 * Bootstrap select
 */
.bootstrap-select{

    .dropdown-menu > li > a {

        font-weight: 300;

    }

}

  /**
   * Bootstrap select extends on checklist page
   */
  .checklist{

    .bootstrap-select{

      width: 100% !important;

    }

    .checklist-form-control-label{

      // display: inline-block;
      // float: left;

    }

  }

.btn-group{
    
    &.open{

        .btn-select {
    
            border-color: $primary-color !important;
            box-shadow: none;

        }

    }

}

.btn-select{
    
    &:focus {
    
        border-color: $primary-color !important;
        box-shadow: none;

    }

}


/**
 * Readonly
 */
.form-control[readonly]{

  background-color: #FFFFFF;

}


/**
 * Form label
 */
 .form-horizontal{

 	.form-control-label{

 		text-align: left;
    display: block;

 	}

 }


 /**
  * Form focus
  */
  .form-control{
      
      &:focus{

          border-color: $primary-color;
          box-shadow: none;

      }

  }


/**
* Form group
*/
.form-horizontal{

    .form-group {

        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: 20px;

  }

  .input-group{

    margin-bottom: 0px;

  }

}

 /**
  * Required field
  */
  .required{

  	color: $error-color
  	
  }


/**
 * Checkbox
 */
.checkbox-custom {

    label{

        padding-left: 21px;

        &:after, &:before{

        margin-left: -7px;

        }

    }

    input{

        &[type=checkbox]{
      
            margin-left: -8px;

        }
        
    }

}


/**
 * Radiobutton
 */
.radio-custom {

   label{

       padding-left: 21px;

       &:after, &:before{

       margin-left: -7px;

       }

   }

   input{

       &[type=radio]{
     
           margin-left: -8px;

       }
       
   }

}


/**
 * Lista
 */
.label-select_product ~ label{

   &.error {
     
     display: block;
     padding-left: 15px;

   }

}

.insurancecompany-container{

  display: none;
  padding-left: 15px;

  .toogle-insurancecompany-label{

    padding-left: 0px !important;

  }

  .toogle-insurancecompany-label ~ label{

    &.error {
      
      display: block;

    }

  }

}

.toogle-insurancecompany{

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  .form-control-label{

    &.header{

      padding-bottom: 0px;

    }

  }

  .checkbox-custom{

    width: 50%;

  }

}

.product-label ~ label{

  &.error {
    
    display: block;
    padding-left: 15px;

  }

}

.product-label-header{

  font-weight: 500;
  width: 100%;

}


/**
 * Dropzone
 */
.drop {
     background-color: #FFF;
     padding: 130px 50px;
     margin-bottom: 10px;
     border-color: #ccc;
     border-radius: 3px;
     border-style: dashed;
     border-width: 3px;
     color: #7f858a;
     font-weight: 400;

     span{

      display: block;

     }

     .text-center{

      text-align: center;

     }

    #upl {
        display: block;
        position: absolute;
        top: -9999px;
    }

    a{

        &.btn-primary{

            color: $color-white;

            &:hover{

               color: $color-white; 
               
            }

        }

    }

 }