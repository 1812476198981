table.footable,
table.footable-details {
	position: relative;
	width: 100%;
	border-spacing: 0;
	border-collapse: collapse;
}
table.footable-details {
	margin-bottom: 0;
}
table > tbody > tr > td > span.footable-toggle {
	margin-right: 8px;
	opacity: 0.3;
}
table > tbody > tr > td > span.footable-toggle.last-column {
	margin-left: 8px;
	float: right;
}
table.table-condensed > tbody > tr > td > span.footable-toggle {
	margin-right: 5px;
}
table.footable-details > tbody > tr > th:nth-child(1) {
	min-width: 40px;
	width: 120px;
}
table.footable-details > tbody > tr > td:nth-child(2) {
	word-break: break-all;
}

table.footable-details > thead > tr:first-child > th,
table.footable-details > thead > tr:first-child > td,
table.footable-details > tbody > tr:first-child > th,
table.footable-details > tbody > tr:first-child > td,
table.footable-details > tfoot > tr:first-child > th,
table.footable-details > tfoot > tr:first-child > td {
	border-top-width: 0;
}
table.footable-details.table-bordered > thead > tr:first-child > th,
table.footable-details.table-bordered > thead > tr:first-child > td,
table.footable-details.table-bordered > tbody > tr:first-child > th,
table.footable-details.table-bordered > tbody > tr:first-child > td,
table.footable-details.table-bordered > tfoot > tr:first-child > th,
table.footable-details.table-bordered > tfoot > tr:first-child > td {
	border-top-width: 1px;
}

div.footable-loader {
	vertical-align: middle;
	text-align: center;
	height: 300px;
	position: relative;
}
div.footable-loader > span.fooicon {
	display: inline-block;
	opacity: 0.3;
	font-size: 30px;
	line-height: 32px;
	width: 32px;
	height: 32px;
	margin-top: -16px;
	margin-left: -16px;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-animation: fooicon-spin-r 2s infinite linear;
	animation: fooicon-spin-r 2s infinite linear;
}
table.footable > tbody > tr.footable-empty > td {
	vertical-align: middle;
	text-align: center;
	font-size: 30px;
}
table.footable > tbody > tr > td,
table.footable > tbody > tr > th {
	display: none;
}
table.footable > tbody > tr.footable-detail-row > td,
table.footable > tbody > tr.footable-detail-row > th {
	display: table-cell;
}
@-webkit-keyframes fooicon-spin-r {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}

@keyframes fooicon-spin-r {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}

/* Glyphicons Icons - We're not actually using Glyphicons classes but instead provide a simple mapping from Glyphicons to FooTable class names. */
.fooicon {
	position: relative;
	top: 1px;
	display: inline-block;
	font-family: 'Glyphicons Halflings';
	font-style: normal;
	font-weight: 400;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.fooicon:before,
.fooicon:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.fooicon-loader:before {
	content: "\e030";
}
.fooicon-plus:before {
	content: "\2b";
}
.fooicon-minus:before {
	content: "\2212";
}
.fooicon-search:before {
	content: "\e003";
}
.fooicon-remove:before {
	content: "\e014";
}
.fooicon-sort:before {
	content: "\e150";
}
.fooicon-sort-asc:before {
	content: "\e155";
}
.fooicon-sort-desc:before {
	content: "\e156";
}
.fooicon-pencil:before {
	content: "\270f";
}
.fooicon-trash:before {
	content: "\e020";
}
.fooicon-eye-close:before {
	content: "\e106";
}
.fooicon-flash:before {
	content: "\e162";
}
.fooicon-cog:before {
	content: "\e019";
}