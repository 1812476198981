/**
 * Page error
 */
.page-error .error-mark {
	margin-bottom: 33px;
}

.page-error header h1 {
  	font-size: 10em;
  	font-weight: 400;
}

.page-error header p {
  	margin-bottom: 30px;
  	font-size: 30px;
  	text-transform: uppercase;
}

.page-error h2 {
  	margin-bottom: 30px;
}

.page-error .error-advise {
  	margin-bottom: 25px;
  	color: #a9afb5;
}


/**
 * Validation error
 */
label{

    &.error{

        color: $error-color;
        font-size: 12px;
        font-weight: 400;

	}

}

.checkbox-container{

  label{

      &.error{

          padding-left: 15px;

    }

  }

}