.widget {
  
	.height-200{

		height: 200px;

	}

	&.text-center{

		text-align: center;

	}

	.blue{

		background-color: #62a8ea;

	}

	.turquoise{

		background-color: #57c7d4;

	}

	.green {

		background-color: #46be8a;

	}

	.red {

		background-color: #F9686A;
		
	}

	.yellow {

		background-color: #f2a654;
		
	}

	.bg-blue-grey-400 {

	    background-color: #a3afb7;

	}

	.bg-blue-grey-700{

		background-color: #37474f;

	}

	.bg-blue{

		background-color: #3b5998;

	}

	.widget-stat{

		margin-bottom: 25px;
		box-shadow: 1px 2px 5px 0px rgba(50, 50, 50, 0.28);

	}

	.widget-checklist{
	
		border-bottom: 4px solid #00bcd4;
		margin-bottom: 25px;
		box-shadow: 1px 2px 5px 0px rgba(50, 50, 50, 0.28);

		.sub-title{

		    text-align: left;

		}

		@media only screen and (max-width: 1330px) {

			.title{

			    font-size: 30px !important;

			}

		}

		@media only screen and (max-width: 980px) {

			.title{

			    font-size: 22px !important;

			}

			.sub-title{

			    font-size: 18px !important;

			}

		}

		@media only screen and (max-width: 767px) {

			.title{

			    font-size: 40px !important;

			}

		}

		.btn-floating{

			&.btn-sm{

				margin-bottom: 6px;

			}

		}

	}

	.progress{

		margin-bottom: 0px;

	}

}